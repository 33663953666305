import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchOnboardingApi from '../../api/onboarding';

export const fetchOnboarding = createAsyncAction('fetch-onboarding', fetchOnboardingApi);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    clearOnboarding(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchOnboarding),
  },
});

export const { reducer: onboarding } = onboardingSlice;
export const { clearOnboarding } = onboardingSlice.actions;
