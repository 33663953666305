import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { RocketLaunchOutlined, ContactSupportOutlined } from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import Task from './Task';
import withVenue from '../../hoc/withVenue';
import { getIntroTasksState } from '../../store/introTasks/selectors';
import { clearTasks, fetchIntroTasks, updateIntroTasks } from '../../store/introTasks';
import WelcomeDialog from './WelcomeDialog';
import useHasChanged from '../../hooks/useHasChanged';
import KebabMenu from '../../components/KebabMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1.5rem',
  },
  launch: {
    marginBottom: '1.5rem',
  },
  progress: {
    marginTop: '0.2rem',
    marginBottom: '1rem',
    fontSize: '0.8rem',
  },
  icon: {
    marginLeft: 'auto',
    flexBasis: 0,
    paddingRight: 0,
  },
  remove: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  chatInfo: {
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    display: 'flex',
    gap: '8px',
    padding: '16px',
  },
  root: {
    height: '8px',
    backgroundColor: '#E0E0EB',
    borderRadius: '8px',
  },
  bar: {
    backgroundColor: '#8517B5',
    borderRadius: '8px',
  },
}));

const TasksCard = ({ openExternalLink, currentVenueId, venueType, launchDate, email }) => {
  const localStorageIntroTasks = JSON.parse(localStorage.getItem('hideIntroTasks'));
  const hasVenueIdChanged = useHasChanged(currentVenueId);
  const { show } = useIntercom();

  const classes = useStyles();
  const dispatch = useDispatch();

  const openMailLink = () => {
    window.location.href = `mailto:${email}`;
  };

  const taskActions = {
    NONE: 'NONE',
    LINK_TO_VENUE: '/settings/payments',
    LINK_TO_SETTINGS: '/settings/venue',
    LINK_TO_PRODUCTS: '/items/menu-items',
    LINK_TO_GUINNESS_PRODUCT: '/menu-items/1',
    LINK_TO_CORONA_PRODUCT: '/menu-items/15',
    LINK_TO_MENU: '/menus',
    LINK_TO_QR: '/qrcodes',
    OPEN_QR_ORDER: openExternalLink,
    OPEN_EMAIL: openMailLink,
    ORDER_BRAND_PACKAGING: 'https://store.sessions.co.uk/',
    BIDFOOD_ORDER: 'https://www.bidfood.co.uk/login/',
    DELIVERY_OPENINGS_TIMES: '/settings/venue',
  };

  const [tasklist, setTasklist] = useState(null);
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false);
  const [progressCount, setProgressCount] = useState(tasklist?.filter((task) => task.done).length);
  const [updateTaskLoading, setUpdateTaskLoading] = useState(false);

  const { loading: tasksLoading, data: tasksData } = useSelector(getIntroTasksState);
  useEffect(() => {
    if (hasVenueIdChanged) {
      dispatch(fetchIntroTasks());
    }
  }, [hasVenueIdChanged, dispatch]);

  useEffect(() => {
    dispatch(fetchIntroTasks());
    return () => {
      dispatch(clearTasks());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tasksLoading || !tasksData) return;
    const { tasks } = tasksData || {};
    const rawTasklist = [...(tasks || [])];
    const welcomeIndex = rawTasklist.findIndex((item) => item.name === 'WELCOME');

    let welcomeTask;
    if (welcomeIndex !== -1) {
      [welcomeTask] = rawTasklist.splice(welcomeIndex, 1);
    }

    setTasklist(rawTasklist);
    setProgressCount(rawTasklist?.filter((taskProgress) => taskProgress.done).length);
    if (!welcomeTask) return;

    const { done: isWelcomeClosed, id } = welcomeTask || {};
    setIsWelcomeOpen(!(isWelcomeClosed ?? true));

    if (!isWelcomeClosed && welcomeTask.handler === 'FE') dispatch(updateIntroTasks(id));
  }, [dispatch, tasksData, tasksLoading]);

  const progressTotal = tasklist?.length;
  const progress = 100 * (progressCount / tasklist?.length);

  const isComplete = progressCount === progressTotal;

  const setTaskCompleted = useCallback(
    (id) => {
      setUpdateTaskLoading((prev) => ({ ...prev, [id]: true }));
      const task = tasklist.find((item) => item.id === id);
      if (task.handler === 'FE')
        dispatch(updateIntroTasks(id)).then(() =>
          setUpdateTaskLoading((prev) => ({ ...prev, [id]: false })),
        );
    },
    [dispatch, tasklist],
  );

  const handleRemoveSection = useCallback(() => {
    localStorage.setItem('hideIntroTasks', JSON.stringify(true));
  }, []);

  const closeWelcome = () => {
    setIsWelcomeOpen(false);
  };

  const date = launchDate && moment(launchDate).local().format('D MMM YYYY');

  return !localStorageIntroTasks ? (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container alignItems="flex-start">
            {venueType === 'DUET' && launchDate ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderBottom: '#E0E0EB 1px solid',
                    display: 'flex',
                    gap: '8px',
                    paddingBottom: '8px',
                  }}
                >
                  <RocketLaunchOutlined />
                  <Typography sx={{ paddingBottom: '8px' }}>
                    Launch date: <b>{date}</b>
                  </Typography>

                  <Grid item xs={2} className={classes.icon}>
                    <KebabMenu
                      items={[
                        {
                          name: 'Remove tasks section',
                          handler: () => {
                            handleRemoveSection();
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.title} variant="h4" sx={{ paddingTop: '8px' }}>
                    Launch day checklist
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                {!isComplete && (
                  <>
                    <Grid item xs={10}>
                      <Typography className={classes.title} variant="h4">
                        You can get started by completing these tasks
                      </Typography>
                    </Grid>

                    <Grid item xs={2} className={classes.icon}>
                      <KebabMenu
                        items={[
                          {
                            name: 'Remove tasks section',
                            handler: () => {
                              handleRemoveSection();
                            },
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          <LinearProgress
            classes={{ root: classes.root, bar: classes.bar }}
            variant="determinate"
            value={progress}
          />

          <Typography variant="body2" className={classes.progress}>
            {!isComplete ? `${progressCount} out of ${progressTotal} steps complete` : 'All done!'}
          </Typography>
          {tasklist?.map(({ id, title, description, done, label, action, handler }) => (
            <Task
              key={id}
              completed={done}
              title={title}
              description={description === 'NONE' ? null : description}
              buttonTitle={label === 'NONE' ? null : label}
              buttonLink={taskActions[action]}
              setItem={() => setTaskCompleted(id)}
              progressCount={progressCount}
              setProgressCount={setProgressCount}
              updateTaskLoading={updateTaskLoading[id]}
              handler={handler}
            />
          ))}
          {isComplete && (
            <Box className={classes.remove}>
              <MuiLink onClick={handleRemoveSection}>Remove this section</MuiLink>
            </Box>
          )}
        </CardContent>
        <Grid className={classes.chatInfo}>
          <ContactSupportOutlined />
          <Typography>
            Not sure what to do? Our accounts team can help via the chat.
            <br />
            <MuiLink underline="always" href="#" onClick={show}>
              Ask for help
            </MuiLink>
          </Typography>
        </Grid>
      </Card>
      <WelcomeDialog open={isWelcomeOpen} onClose={closeWelcome} />
    </Grid>
  ) : (
    <></>
  );
};
export default withVenue(TasksCard);
