import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { Switch, TextField } from 'formik-mui';
import { Card, CardContent, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AutoComplete from '../AutoComplete';

import { fetchAvailablePrinters } from '../../store/printers';
import { getAvailablePrintersState } from '../../store/printers/selectors';
import UniversalSave from '../UniversalSave';

const PrinterForm = ({ initialValues, onSubmit }) => {
  const destinationTypes = ['DRINKS', 'FOOD', 'ALL'];
  const autocutTypes = ['FULL', 'PARTIAL'];

  const PrinterSchema = Yup.object().shape({
    printerId: Yup.string().min(3, 'Too Short!').required('Please choose a printer'),
    destination: Yup.string().min(3, 'Too Short!').required('Please choose a destination'),
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAvailablePrinters());
  }, [dispatch]);

  const { data: availablePrinters } = useSelector(getAvailablePrintersState);

  return (
    <Card>
      <Formik onSubmit={onSubmit} validationSchema={PrinterSchema} initialValues={initialValues}>
        {({ values, dirty, setFieldValue, isValid, resetForm, errors }) => (
          <>
            <UniversalSave
              isValid={isValid}
              errors={errors}
              dirty={dirty}
              onSave={() => onSubmit(values)}
              onDiscard={resetForm}
            />
            <Form>
              <CardContent>
                <section>
                  <FormGroup>
                    {availablePrinters && (
                      <AutoComplete
                        name="printer"
                        addUnknownOff
                        required
                        id="printer"
                        label="Select Printer *"
                        options={availablePrinters}
                        optionValueKey="printerId"
                        optionLabelKey="printerId"
                        variant="outlined"
                        margin="normal"
                        onSelect={(value) => {
                          setFieldValue('printerId', value);
                        }}
                      />
                    )}
                  </FormGroup>
                </section>
                <section>
                  <FormGroup>
                    <Field
                      component={TextField}
                      select
                      fullWidth
                      name="destination"
                      label="Select Destination"
                      variant="outlined"
                      margin="normal"
                      required
                      onChange={({ target }) => {
                        setFieldValue('destination', target.value);
                      }}
                    >
                      {destinationTypes.map((destination) => (
                        <MenuItem value={destination} key={nanoid()}>
                          {destination}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormGroup>
                </section>
                <section>
                  <FormGroup>
                    <Field
                      component={TextField}
                      select
                      fullWidth
                      name="autocut"
                      label="Autocut"
                      variant="outlined"
                      margin="normal"
                      required
                      onChange={({ target }) => {
                        setFieldValue('autocut', target.value);
                      }}
                    >
                      {autocutTypes.map((type) => (
                        <MenuItem value={type} key={nanoid()}>
                          {type}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormGroup>
                </section>
                <section>
                  <FormControlLabel
                    label="Double print ticket"
                    labelPlacement="start"
                    control={
                      <Field
                        component={Switch}
                        color="primary"
                        type="checkbox"
                        name="allowDoublePrint"
                        id="ingredient_isAlcoholic"
                      />
                    }
                  />
                </section>
              </CardContent>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default PrinterForm;
