import { getBare } from './httpRequest';

const fetchOnboarding = async () => {
  try {
    const response = await getBare(`onboarding/${localStorage.getItem('venue_id')}`);

    return response.data;
  } catch (error) {
    return [];
  }
};

export default fetchOnboarding;
